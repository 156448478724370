<!-- Localized -->
<template>
<div >
<div v-for="content in options" :key="content.id">
    <div  v-if="content && content.type == 'resource'" class="cursor-pointer items-center gap-3 py-2 border-t border-t-base-200" @click="handleContent(content)">
     <div class="flex ml-14 items-center"><TextTemplate class="fill-current" /><span class="ml-2">{{content.name}}</span></div> 
    </div>
    <div v-if="content.type == 'directory'" class="cursor-pointer items-center gap-3 py-2 border-t border-t-base-200" @click="handleSelected(content.id)">
        <div class="flex ml-14 items-center">
            <FolderIcon class="text-primary fill-current" /><span class="ml-2">{{content.name}}</span>
            <span class="ml-auto mr-2"><DropdownIcon class="dropdown_btn fill-current" style="transform: rotate(360deg)" /></span>
        </div> 
    </div>
        <TemplateDropDown
        v-if="content.content && content.content.length && contentSelected"
        :options = content.content
        :selectedFolderId = content.id
        @template:sequence = handleSelectedContent($event)
        class="ml-7"
        />
    </div>
</div>
</template>
<script>
import FolderIcon from "@/assets/icons/email-admin/icon-folder.svg";
import TextTemplate from "@/assets/icons/email-admin/icon-text-temp.svg";
// import TemplateDropDown from "./templates-dropdown.vue";
import DropdownIcon from "@/assets/icons/email-admin/icon-dropdown.svg";
const TemplateDropDown = () => import("./templates-dropdown.vue");
 export default {
     name: 'folder-dropdown',
    components: {
        FolderIcon,
        TextTemplate,
        TemplateDropDown,
        DropdownIcon
    },
    props: {
        options:{
            type: Array,
        },
        selectedFolderId: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            contentSelected: null,
        }
    },
    methods: {
        handleSelected(id){
            if(this.contentSelected && this.contentSelected == id )
                this.contentSelected = null;
            else this.contentSelected = id;
        },
        handleContent(content) {
            console.log(content)
            this.$emit("template:sequence", content);
            this.contentSelected = null;
        },
        handleSelectedContent(content) {
            this.$emit("template:sequence", content);
        }
    }

 }
</script>
