import { render, staticRenderFns } from "./default-template.html?vue&type=template&id=17f0bd8c&scoped=true&external"
import script from "./default-template.js?vue&type=script&lang=js&external"
export * from "./default-template.js?vue&type=script&lang=js&external"
import style0 from "./default-template.scss?vue&type=style&index=0&id=17f0bd8c&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17f0bd8c",
  null
  
)

export default component.exports