import { render, staticRenderFns } from "./email-days.html?vue&type=template&id=31f4330b&scoped=true&external"
import script from "./email-days.js?vue&type=script&lang=js&external"
export * from "./email-days.js?vue&type=script&lang=js&external"
import style0 from "./email-days.scss?vue&type=style&index=0&id=31f4330b&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f4330b",
  null
  
)

export default component.exports