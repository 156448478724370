import tabs from "@/components/dashboard-components/tabs";
import emailTempModal from "@/components/emailtemplatemodal";
import searchModal from "@/components/search-modal";
import emailSequence from "./email-sequence";
import emailTemplate from "./email-template";
import BrowseDir from "@/components/browse-dir";
import Sequence from "@/components/sequence";
import { checkPermission } from "@shared/utils/functions"

export default {
  name: "email-admin",
  components: {
    tabs,
    emailTempModal,
    "email-sequence": emailSequence,
    "email-template": emailTemplate,
    searchModal,
    BrowseDir,
    Sequence,
  },
  data() {
    return {
      fakeData: {
        tabs: {
          selected: "templates"
        },
      },
      isModalOpen: false,
      isDirModalOpen: false,
      isSearchModal: false,
      isSequenceModal: false,
      newModal:false
    };
  },
  title: "Email Admin",
  computed: {
    tabsList() {
        return  [
          {
            id: "templates",
            label: this.$t('pages.email_admin.templates')
          },
          {
            id: "sequences",
            label: this.$t('pages.email_admin.sequences')
          },
        ]
    }
  }, 
  methods: {
    checkPermission,
    async selected() {
      this.isSearchModal = false;
      this.isSequenceModal = true;
    },
    selectTab(id) {
      this.fakeData.tabs.selected = id;
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: this.fakeData.tabs.selected,
        },
      })
    },
    showDetails() {
      this.showModal = true;
    },
    EmailTempModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    BrowseDirModal() {
      this.isDirModalOpen = !this.isDirModalOpen;
    },
    SearchModal() {
      this.isSearchModal = !this.isSearchModal;
      this.newModal=true

    },
    onModelClose() {
      this.isModalOpen = !this.isModalOpen;
    },
    onDirModalClose() {
      this.isDirModalOpen = !this.isDirModalOpen;
    },
    onSearchModalClose() {
      this.isSearchModal = !this.isSearchModal
    },
    getPermissions(type){
        return this.checkPermission(`${type}.read`) && this.checkPermission(`${type}.create`)
    }
  },
  props: {
    CreateEmailTempModal: Function,
  },
  mounted() {
    this.fakeData.tabs.selected = this.$route?.query?.tab || "templates";
  }
};
