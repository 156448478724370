<!-- Localized -->
<template>
<div
    v-if="value"
    class="block max-w- truncate "
    :title="value"
    :class="['status', `status--${value}`]"
>
    {{value}}
</div>
</template>

<script>

export default {
    name: 'status',
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            // statuses: {
            //     0: 'To Do',
            //     1: 'In Progress',
            //     2: 'Done',
            // }
        }
    },
};
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
.status {
    // margin: 0 auto;
    // display: inline-flex;
    // align-items: center;
    // justify-content: center;
    // width: 100px;
    max-width: 8rem;
    // height: 28px;
    border-radius: 8px;
    border-style: solid;
    border-width: 2px;
    // to be changed according to case statuses
    &--Started {
        color: #029ADB;
        border-color: #029ADB;
    }
    &--Completed {
        color: #2ADB47;
        border-color: #2ADB47;
    }
    &--2,&--Cancelled {
        color: #FF0000;
        border-color: #FF0000;
    }
}
</style>
